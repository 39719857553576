import React from "react";
import ComponentResolver from "../helpers/componentResolver";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export function getOptions(content) {
  let options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="bold">{text}</span>,
      [MARKS.ITALIC]: (text) => <span className="italic">{text}</span>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const item_id = node.data.target.sys.id;
        const itemList = content.references;
        const item = itemList.find((x) => x.contentful_id === item_id);
        if (item) return <ComponentResolver key={item_id} component={item} />;
        return "";
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data;
        return (
          <a href={uri} className="underline">
            {children}
          </a>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div className="generic-page__text">{children}</div>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const item_id = node.data.target.sys.id;
        const itemList = content.references;
        const item = itemList.find((x) => x.contentful_id === item_id);
        if (!item.gatsbyImageData) return <></>;
        return (
          <GatsbyImage
            image={getImage(item)}
            alt={item.gatsbyImageData.description}
            className="genericPage__Image"
          />
        );
      },
    },
  };
  return options;
}

export default {
  getOptions: getOptions,
};
